<template>
    <div>
        <h2>{{ $t("compta.impayes") }}</h2>

        <div class="box">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">{{ $t('payment.stats_impayes') }}</h3>
                </div>
                <div class="col-auto">
                    <b-button v-b-toggle.duedateStat variant="primary" class="mb-0">Voir <font-awesome-icon :icon="['fal', 'angle-down']" /><font-awesome-icon :icon="['fal', 'angle-up']" /></b-button>
                </div>
            </div>
            <b-collapse v-if="stats_loaded" id="duedateStat">
                <div class="row stats justify-content-center pt-4">
                    <div class="col mb-3 mb-md-0" v-for="(accountin_plan, key) in stats" :key="key">
                        <b style="border:none; width:100%">{{ accountin_plan }}</b>{{ key }}
                    </div>
                    <div class="col mb-3 mb-md-0">
                        <b style="border:none; width:100%">{{ total }}</b>{{ $t('payment.total_impaye') }}
                    </div>
                </div>
            </b-collapse>
            <div v-else>
                <LoadingSpinner />
            </div>
        </div>

        <div class="box">
            <DateRangeV2
                :periodLabel="$t('date_range.jour')"
                :start.sync="start_date"
                :startDefault="start_default"
                :end.sync="end_date"
                persistId="impayes"
                :dateSelect=true
                @submit="loadUnpaid"
            />
            <b-button class="col-3" variant="primary" @click.prevent="display_all">{{ $t("action.display_unpaid") }}</b-button>

            <CustomTable
                id_table="impayes"
                ref="impayes"
                :items="duedates"
                :busy.sync="table_busy"
                primaryKey="duedate_id"
                :hrefsRoutes="config_table_hrefs"
                :externSlotColumns="extern_slot_columns"
                :hide_if_empty="true"
                :rawColumns="rawColumnNames"
                :columsAddBegin="['preview']"
            >
                <template v-slot:[`custom-slot-cell(invoice_saillie)`]="{ data }">
                    <font-awesome-icon class="text-success" v-if="data.invoice_saillie" :icon="['fal', 'check-circle']" />
                    <font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
                </template>

                <template v-slot:[`custom-slot-cell(preview)`]="{ data }">
                    <a href="" class="" @click.prevent="quickPreview(data.invoice_id)">
					    <font-awesome-icon :icon="['fas', 'eye']" />
                    </a>
				</template>
                <template v-slot:custom-slot-cell(horses)="{ data }">
                    <span v-if="data.horses.length < 4" >
                        <span v-for="horse, index in data.horses" :key="index">
                            <router-link :to="{ name: 'horseFiche', params: { horse_id: horse.horse_id }}">
                                {{horse.horse_nom}}
                            </router-link>
                            <span v-if="index != data.horses.length-1">, </span>
                        </span>
                    </span>
                    <span v-else>
                        <span :id="data.invoice_num">
                            <span v-for="horse, index in data.horses" :key="index">
                                <router-link  :to="{ name: 'horseFiche', params: { horse_id: horse.horse_id }}">
                                    <span v-if="index < 3">{{horse.horse_nom}}</span>
                                </router-link>
                                <span v-if="index < 2">, </span>
                            </span>...
                        </span>
                        <b-popover
                            :target="data.invoice_num"
                            triggers="hover"
                            placement="right"
                        >
                            <router-link v-for="horse in data.horses" :key="horse.horse_id" :to="{ name: 'horseFiche', params: { horse_id: horse.horse_id }}">
                                {{horse.horse_nom}}<br>
                            </router-link>
                        </b-popover>
                    </span>
                </template>
                <template v-slot:custom-slot-cell(trackings_link)="{ data }">
                    <a href="" class="" @click.prevent="openShutterTrackings(data.invoice_id)">{{ $t('global.see_trackings') }}</a>
                </template>
            </CustomTable>
        </div>

        <b-modal ref="modalPayment" hide-footer size="xl">
            <template v-slot:modal-title>
                    {{ $t("payment.add_payment") }}
            </template>

            <AddPayment ref="addPayment" :invoice_ids="invoice_ids" :invoice_accountingplans="invoice_accountingplans" :tiers_id="tiers_id" :invoice_balances="invoice_balances" :invoice_nums="invoice_nums" :processing.sync="processing" :ready.sync="ready" :submit_payment.sync="submit_payment"></AddPayment>

            <div class="d-none col-8 m-auto">
                <b-button v-if="ready" block pill variant="primary" @click.prevent="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
            </div>
        </b-modal>

        <b-modal ref="modalRelance" hide-footer>
            <template v-slot:modal-title>
                    {{ $t("invoice.relance_manuelle") }}
            </template>

            <div class="form-group">
                <label for="date">{{ $t('invoice.relance_date') }}</label>
                <e-datepicker v-model="date"></e-datepicker>
            </div>

            <div class="form-group">
                <label for="message">{{ $t('invoice.relance_message') }}</label>
                <input type="text" v-model="message" class="form-control">
            </div>

            <div class="col-8 m-auto">
                <b-button block pill variant="primary" @click.prevent="onSubmitRelance"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
            </div>
        </b-modal>

        <b-modal size="xl" ref="modelPreview" hide-footer @hidden="base64 = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>

		<ModalPrintInfosTiers ref="modal_print_infos_tiers"/>
		<ModalSendImpayes ref="modal_send_impayes" @submit="stopSpin"/>
        <ModalSoldeInvoiceCC ref="modal_solde_invoice" @submit="loadUnpaid" />
    </div>
</template>


<script type="text/javascript">
	import Vue from 'vue'
    import TableAction from "@/mixins/TableAction.js"
    import Invoice from "@/mixins/Invoice.js"
    import Navigation from "@/mixins/Navigation.js"
    import Payment from "@/mixins/Payment.js"
	import Config from "@/mixins/Config.js"
	import { EventBus } from 'EventBus'
    import Shutter from '@/mixins/Shutter.js'
	import ShutterInvoice from '@/mixins/shutters-manager/Invoice.js'

    export default {
        name: "list",
        mixins: [Config, TableAction, Invoice, Navigation, Payment, Shutter, ShutterInvoice],
        props: [],
        data () {
            return {
                duedates: null,
                table_busy: true,
                modal_loaded: false,
                ready: false,
                date: new Date(),
                invoice_balances: 0,
                processing: false,
                base64: null,
                start_date: null,
                message: null,
                start_default: new Date(new Date().setMonth(new Date().getMonth() - 3)),
                end_date: null,
                config_table_hrefs: {
                    'invoice.tiers.tiers_rs': {
                        routeUniqueName: 'tiersFiche_1',
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'invoice.tiers.tiers_id'
                        }
                    },
                    // 'author.tiers_rs': {
                    //     routeUniqueName: 'tiersFiche_2',
                    //     routeName: 'tiersFiche',
                    //     params: {
                    //         tiers_id: 'author.tiers_id'
                    //     }
                    // }
                },
                invoice_ids: null,
                tiers_id: 0,
                invoice_accountingplans: null,
                invoice_nums: null,
                rawColumnNames: ['tiers.tiers_rs'],
				extern_slot_columns: ['preview', 'horses', 'trackings_link'],
                events_tab: {
                    'TableAction::goToAddPayment': this.check_display_payment_form,
                    'TableAction::goToPdfInvoice': (params) => {
                        this.pdfInvoice(params.invoice_ids, params.invoice_nums)
					},
                    'TableAction::goToSetRelanceManuelle': (params) => {
                        this.setRelanceManuelle(params.invoice_ids)
					},
					'TableAction::goToSendInvoice': (params) => {
						let unique_ids = new Set(params.author_ids)
						if(unique_ids.size > 1) {
							this.infoToast("toast.model_choice")
						}
						if(unique_ids.size == 1) {
							this.openModalSendImpayes(params.invoice_ids, unique_ids)
						}
						else {
							this.callSendInvoice(params.invoice_ids)
						}
                    },
                    'TableAction::goToPrintInfos': (params) => {
                        this.openModalPrintInfos(params)
                    },
                    'TableAction::goToModifyInvoiceSaillie': (invoice_ids) => {
                        this.$refs.impayes.unselectAll()
						this.updateInvoiceSaillie(invoice_ids).then(() => {
                            this.table_busy = true
                            this.loadUnpaid()
				        })
					},
                    'TableAction::goToSoldeAvoir': (invoice) => {
                        this.soldeAvoirCC(invoice)
                    },
                },
                total: 0,
                stats: {},
                stats_loaded: false,
                submit_payment: false,
                solde_tiers_id: null
            }
        },
        methods: {
            async loadUnpaid() {
				this.table_busy = true
                this.stats_loaded = false
                this.stats = {}
                const duedates = await this.getUnpaidInvoices(this.start_date, this.end_date)
                this.duedates = await this.formatUnpaid(duedates)

                this.stats_loaded = true
                this.table_busy = false
            },
            async formatUnpaid(duedates) {
                this.total = 0
                let duedateFormated = []
                
                if(!duedates)
                {
                    return []
                }

				let date_lang_format = Vue.prototype.$i18n.locale()
                for (let i = 0; i < duedates.length; i++) {
                    const temp = duedates[i]
                    temp.href = "tiersFiche"
                    temp.params = { tiers_id: temp.invoice.tiers.tiers_id }
                    // temp.duedate_balance_vanilla = temp.duedate_balance
					// temp.duedate_balance = await this.priceFormat(temp.duedate_balance, temp.invoice.author.tiers_currency)
					temp.invoice_id = temp.invoice.invoice_id
					temp.invoice_num = temp.invoice.invoice_num
					temp.invoice_balance = temp.invoice.invoice_balance
					temp.invoice_tiers = temp.invoice.invoice_tiers
					temp.invoice_accountingplan = temp.invoice.invoice_accountingplan

                    const tracking_received = temp.invoice.tracking.filter(tracking => tracking.invoicetracking_type == "received")
                    temp.received_date = this.getArrayObjProperty(tracking_received, "invoicetracking_date")
                    if(temp.received_date.length == 0)
                    {
                        temp.received_date = ""
                    }


					const tracking_mail = temp.invoice.tracking.filter(tracking => tracking.invoicetracking_type == "mail" || tracking.invoicetracking_type == "autre")

					let relance = []
					for (let j = 0; j < tracking_mail.length; j++) {
						let tracking_date = this.getArrayObjProperty(tracking_mail, "invoicetracking_date")[j]
						let tracking_modellabel = this.getArrayObjProperty(tracking_mail, "invoicetracking_modellabel")[j]
						let tracking_message = this.getArrayObjProperty(tracking_mail, "invoicetracking_message")[j]
						if(tracking_date.length == 0)
						{
							tracking_date = ""
						}

						if(tracking_modellabel) {
							relance.push(Date.parseTz(tracking_date).toLocaleDateString(date_lang_format) + ' - ' + tracking_modellabel + " " + tracking_message)
						}
						else {
							relance.push(Date.parseTz(tracking_date).toLocaleDateString(date_lang_format) + " " + tracking_message)
						}
					}

                    let horses = []
                    let horses_id = []

                    for (let j = 0; j < temp.invoice.details.length; j++) {
                        if(temp.invoice.details && temp.invoice.details[j].horse && !horses_id.includes(temp.invoice.details[j].horse.horse_id)){
                            horses.push(temp.invoice.details[j].horse)
                            horses_id.push(temp.invoice.details[j].horse.horse_id)
                        }
					}
                    temp.horses = horses
                    

					temp.relance = relance.join("<br>")
                    duedateFormated.push(temp);

                    if(!this.stats[temp.invoice.accounting_plan.accountingplan_label]) {
                        this.stats[temp.invoice.accounting_plan.accountingplan_label] = temp.duedate_balance
                    }
                    else {
                        this.stats[temp.invoice.accounting_plan.accountingplan_label] += temp.duedate_balance
                    }

                    this.total += temp.duedate_balance
                }

                this.total = await this.priceFormat(this.total, 'EUR')
                for (const key in this.stats) {
                    
                    this.stats[key] = await this.priceFormat(this.stats[key], 'EUR')
                }
                return duedateFormated
            },
            checkPaymentFormFunction(params){
                this.tiers_id = 0
                if(this.checkPaymentForm(params))
                {
                    this.$refs['modalPayment'].show()
                }
            },

            async onSubmit() {
                if(!this.processing) {
                    this.processing = true
                    let return_payment = await this.$refs['addPayment'].addPayment()
                    if(return_payment) {
                        this.$refs["modalPayment"].hide()
                        this.table_busy = true
                        this.$refs["impayes"].unselectAll()
                        this.tiers_id = 0
                        await this.loadUnpaid()                        
                    }
                }
			},

            async display_all() {
                this.start_date = null
                this.end_date = null
                await this.loadUnpaid()
            },

			openModalPrintInfos(params) {
				const tiers_ids = [...new Set(params.tiers_ids)]
				this.$refs.modal_print_infos_tiers.openModal(tiers_ids, params.nb_selected)
			},

			async callSendInvoice(invoice_ids) {
				const model_type = "unpaid_invoice_notification"
                await this.sendMailInvoices(invoice_ids, model_type)
                EventBus.$emit("TableAction::stopSpin", "goToUnpaidPayment")
            },

			openModalSendImpayes(invoice_ids, unique_author_id) {
				let author_id = unique_author_id.values().next().value
				this.$refs.modal_send_impayes.openModal(invoice_ids, author_id)
			},

			stopSpin() {
				EventBus.$emit("TableAction::stopSpin", "goToUnpaidPayment")
			},

            async setRelanceManuelle(invoice_ids) {
                this.invoice_ids = invoice_ids
                this.$refs["modalRelance"].show()
            },

            async onSubmitRelance() {

                await this.addRelanceManuelle(this.invoice_ids, this.date.toDateInputValue(), this.message)

                this.date = new Date()
                this.message = ""
                this.$refs["impayes"].unselectAll()
                this.$refs["modalRelance"].hide()
                await this.loadUnpaid()
            },

            async quickPreview(invoice_id) {
                this.$refs.modelPreview.show()
                let retour = await this.pdfInvoice([invoice_id], "", true)
                if(retour) {
                    this.base64 = retour
                }
            },

            async soldeAvoirCC(invoice) {
                if(invoice.duedate_balance >= 0) {
                    this.failureToast('toast.solde_non_avoir')
                    this.$refs.impayes.unselectAll()
                    return false
                }
                await this.soldeAvoir(invoice.invoice_id)
                this.$refs.modal_solde_invoice.openModal(invoice.invoice_tiers, invoice.invoice_accountingplan)
            },

            openShutterTrackings(invoice_id) {
                this.openTrackingsShutter(invoice_id)
            }
        },
        watch: {
            submit_payment() {
                this.onSubmit()
            }
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable') ,
            AddPayment: () => import('@/components/Payment/AddPayment'),
            DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2'),
			ModalPrintInfosTiers : () => import('@/components/Tiers/ModalPrintInfosTiers'),
            ModalSendImpayes : () => import('@/components/Tiers/ModalSendImpayes'),
			ModalSoldeInvoiceCC : () => import('@/components/Invoice/ModalSoldeInvoiceCC')
        }
    }
</script>
